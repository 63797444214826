$white: #ffffff !default;
$black: #000000 !default;
$light-green: #e4ffce !default;
$light-orange: #ffefdb !default;
$thick-orange: #ED9D0C !default;
$light-violet: #eee4fd !default;
$light-red: #feecec !default;
$light-grey: #7e92a9 !default;
$thick-grey: #91A2B2 !default;
$light-black: #04164a !default;
$pure-black: #000000 !default;
$thick-red:#fc0808 !default;
$light-blue:#f5faff !default;
$thick-blue: #06144d !default;
$thick-yellow: #F9BB4B !default;
$slate-blue: #616b95 !default;
$shade-10: #2c3e50 !default;
$shade-1: #d7dcdf !default;
$shade-0: #fff !default;
$teal: #1abc9c !default;