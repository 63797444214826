@import "partials/colors.scss";

.homeMobileView{
    margin: 20px;
}
.testingCls{
    color: $thick-red;
}
.tagText, .seeMoreText{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-right: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: $thick-orange;
}
.greyColorText{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: $thick-grey;
}
.overlay {
    position: fixed;
    display: block; 
    overflow: auto; 
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 999; 
    cursor: pointer;
}
.content {
    margin: 15% auto;
    background-color: $white;
    border-radius: 0.50rem;
    width: 90%;
    padding: 20px;
    position: relative;
}
.video {
    margin: 0 auto;
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 110px;
}
.video__content {
    width: 100%;
}
.videoPlayerDetails{
    position: absolute;
    width: 100%;
    padding: 20px 10px;
    background: linear-gradient(182deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.4) 54.17%, rgba(0, 0, 0, 0) 100%);
    border-radius: 15px 15px 0px 0px;
}
.controls {
    text-align: center;
    position: relative;
    top: 50px;
}
.raceImg{
    width: 100%;
    height: 500px;
    border-radius: 15px 15px 0px 0px;
}
.controlsplay{
    margin: 0px 20px;
}
.wrapLabel{
    white-space: pre-wrap;
}
.avatarCls{
    position: relative;
    bottom: 14px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
}
.BoxShadowCls{
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.76) 0.01%, rgba(0, 0, 0, 0.4) 49.48%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 150px;
    position: relative;
    bottom: 32px;
}
audio {
    display: none;
}
.audioDuration{
    width: 100%;
    position: absolute;
    color: #fff;
    top: 32px;
    #duration, #timeleft{
        margin-left: 10px;
        margin-right: 10px;
    }
}
audio::-webkit-media-controls-enclosure {
    background-color: transparent;
    color: $black;
}
audio::-webkit-media-controls-timeline {
    color: $white;
    margin-left: 20px;
    display: none; 
}
audio::-webkit-media-controls-timeline-container{
    background-color: $white;
    color: $white;
    display: none; 
}
audio::-webkit-media-controls-play-button {
    display: none; 
}
audio::-webkit-media-controls-current-time-display{
    color: $white;
    display: none; 
}
audio::-webkit-media-controls-time-remaining-display {
    color: $white;
    display: none; 
}
audio::-webkit-media-controls-time-remaining-display {
    color: $white;
    display: none; 
}
audio::-webkit-media-controls-mute-button {
    display: none;
}
.cardViewCls{
    box-shadow: 0px 8px 56px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    padding: 0px;
}
.videoDetails{
    margin: 20px;
}

// Range Slider
$range-handle-color: $shade-10 !default;
$range-handle-size: 20px !default;
$range-track-color: $shade-1 !default;
$range-track-height: 10px !default;
$range-label-color: $shade-10 !default;
$range-label-width: 60px !default;

.range-slider {
  width: 100%;
}

.range-slider__range {
  -webkit-appearance: none;
  width: 90%;
  height: 5px;
  border-radius: 5px;
  background: $thick-yellow;
  outline: none;
  padding: 0;
  margin: 0;
  margin-left: 20px;

  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $white;
    cursor: pointer;
    transition: background .15s ease-in-out;
    box-shadow: 0 0 0 3px $white,  0 0 0 6px $thick-yellow;

    &:hover {
      background: $thick-yellow;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $thick-yellow;
  }

  &::-moz-range-thumb {
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    background: $thick-yellow;
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: $thick-yellow;
    }
  }

  &:active::-moz-range-thumb {
    background: $thick-yellow;
  }
}

// Range Label
.range-slider__value {
  display: inline-block;
  position: relative;
  width: $range-label-width;
  color: $shade-0;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: $range-label-color;
  padding: 5px 10px;
  margin-left: 8px;

  &:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid $range-label-color;
    border-bottom: 7px solid transparent;
    content: '';
  }
}

// Firefox Overrides
::-moz-range-track {
    background: $range-track-color;
    border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer { 
  border: 0; 
}

@media only screen and (max-width: 600px) {
    .video__content{
        border-radius: 0px;
    }
    .videoDetails{
        margin: 0px;
    }
    .raceImg{
        height: 400px;
    }
}