@import "colors.scss";

.primaryBtn{

}
.secondaryBtn{

}
.googlePlayBtn{
    width: 100%;
    border: none;
    background: $thick-yellow;
    border-radius: 8px;
    padding: 12px 22px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    img{
        margin-right: 10px;
    }
}
.appleStoreBtn{
    width: 100%;
    border: none;
    background: $light-black;
    color: $white;
    border-radius: 8px;
    padding: 12px 22px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    img{
        margin-right: 10px;
    }
}
.dotBtn{
    float: right;
    position: absolute;
    right: 4px;
    border: none;
    background: none;
}