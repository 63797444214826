.text-10 {
    font-size: 10px !important;
}
.text-11 {
    font-size: 11px !important;
}
.text-12 {
    font-size: 12px !important;
}
.text-14 {
    font-size: 14px !important;
}
.text-16 {
    font-size: 16px !important;
}
.text-18 {
    font-size: 18px !important;
}
.text-20 {
    font-size: 20px !important;
}
.text-22 {
    font-size: 22px !important;
}
.text-24 {
    font-size: 24px !important;
}
.text-26 {
    font-size: 26px !important;
}
.text-32 {
    font-size: 32px !important;
}
.text-36 {
    font-size: 36px !important;
}
.text-48 {
    font-size: 48px !important;
}

// line height overrides
.lh-none {
    line-height: 0px !important;
}
.lh-12 {
    line-height: 12px !important;
}
.lh-14 {
    line-height: 14px !important;
}
.lh-16 {
    line-height: 16px !important;
}
.lh-18 {
    line-height: 18px !important;
}
.lh-20 {
    line-height: 20px !important;
}

// font-weight overrides
.fw-200 {
    font-weight: 200 !important;
}
.fw-300 {
    font-weight: 300 !important;
}
.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.fw-700 {
    font-weight: 700 !important;
}
.fw-800 {
    font-weight: 800 !important;
}
.fw-900 {
    font-weight: 900 !important;
}
.fw-bold {
    font-weight: bold;
}

//margin
.margin-t-0 {
    margin-top: 0px;
}
.margin-b-0 {
    margin-bottom: 0px;
}

.margin-tb-10 {
margin-top: 10px;
    margin-bottom: 10px;
}
.margin-tb-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-t-5 {
    margin-top: 5px;
}
.margin-t-10 {
    margin-top: 10px;
}
.margin-t-13 {
    margin-top: 13px;
}
.margin-t-15 {
    margin-top: 15px;
}
.margin-t-20 {
    margin-top: 20px;
}
.margin-t-30 {
    margin-top: 30px;
}
.margin-t-100 {
    margin-top: 100px;
}

.margin-r-5 {
    margin-right: 5px;
}
.margin-r-10 {
    margin-right: 10px;
}
.margin-r-20 {
    margin-right: 20px;
}
.margin-r-30 {
    margin-right: 30px;
}

.margin-l-5 {
    margin-left: 5px;
}
.margin-l-10 {
    margin-left: 10px;
}
.margin-l-20 {
    margin-left: 20px;
}
.margin-l-30 {
    margin-left: 30px;
}


.margin-b-0{
    margin-bottom: 0px;
}
.margin-b-5 {
    margin-bottom: 5px;
}
.margin-b-10 {
    margin-bottom: 10px;
}
.margin-b-20 {
    margin-bottom: 20px;
}
.margin-b-30 {
    margin-bottom: 30px;
}
.margin-b-40 {
    margin-bottom: 40px;
}
.margin-b-50 {
    margin-bottom: 50px;
}

//padding
.padding-t-0 {
    padding-top: 0px;
}
.padding-b-0 {
    padding-bottom: 0px;
}
.padding-10 {
    padding: 10px;
}
.padding-20 {
    padding: 20px;
}
.padding-30 {
    padding: 30px;
}
.padding-l-25 {
    padding-left: 25px;
}
.padding-l-30 {
    padding-left: 30px;
}
.padding-b-20 {
    padding-bottom: 20px;
}
.padding-r-25 {
    padding-right: 25px;
}
.padding-10-20 {
    padding: 10px 20px;
}
.padding-0{
    padding: 0px;
}

//align-text
.text-center {
    text-align: center;
}
.text-black {
    color: $pure-black;
}
.text-white {
    color: $white;
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.cursor-pointer {
    cursor: pointer;
}

.positionRelative{
    position: relative
}

//common css Headers
.headerBox{
    height: 100px;
    padding: 28px 0px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.04);
}  